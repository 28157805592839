import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'
import {GatsbyImage, getImage} from 'gatsby-plugin-image'

import {TitleUppercaseStyle, ArrowLinkStyles} from '../elements'

const PostCard = ({post}) => {
  const image = getImage(post.featureImageSharp)
  const tag = post.tags?.length > 1 ? post.tags[1].name : null

  return (
    <Wrapper>
      <Inner to={`/blog/${post?.slug}/`}>
        <Image>
          <GatsbyImage
            image={image}
            alt={post.title}
            style={{
              display: 'block',
              height: '100%',
            }}
            className="post-card__image"
          />
        </Image>
        {tag && <Tag>{tag}</Tag>}
        <Title>{post.title}</Title>
        {post.excerpt && <Excerpt>{post.excerpt}</Excerpt>}
        <Readmore>Read more</Readmore>
      </Inner>
    </Wrapper>
  )
}

export default PostCard

const Wrapper = styled.article`
  .post-card__image {
    img {
      transition: all 0.2s;
    }
  }

  &:hover {
    .post-card__image {
      img {
        transform: scale(1.04);
      }
    }

    h2 {
      text-decoration: underline;
    }
  }
`

const Inner = styled(Link)`
  display: block;
  height: 100%;
`

const Image = styled.div`
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
  margin: 0 0 16px;
  height: 270px;
`

const Tag = styled.div`
  ${TitleUppercaseStyle}
  opacity: 1;
  margin: 0 0 6px;
  font-size: 1.6rem;
  color: ${props => props.theme.color.grey.darkest};
`

const Title = styled.h2`
  color: ${props => props.theme.color.text.base};
  margin: 0 0 12px;
  font-size: 2.8rem;
  transition: all 0.2s;
`

const Excerpt = styled.p`
  font-size: 1.8rem;
  color: ${props => props.theme.color.text.lightest};
  margin: 0 0 16px;
`

const Readmore = styled.div`
  ${ArrowLinkStyles}
`
