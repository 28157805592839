import styled from 'styled-components'

import theme from '../styles/theme'
import prismCodeStyles from '../styles/prism'

const PostContent = styled.div`
  font-size: 1.8rem;
  color: ${theme.color.text.base};

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    font-size: 2rem;
  }

  /* Headings ----------------------------------- */

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${props => props.theme.color.text.darker};
    font-weight: 500;
    margin: 0 0 0.7em;
  }

  h2 {
    font-size: 2.8rem;

    @media (min-width: ${theme.breakpoints.lg}) {
      font-size: 3.4rem;
    }
  }

  h3 {
    font-size: 2.3rem;

    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      font-size: 2.4rem;
    }
  }

  h4 {
    font-size: 2rem;

    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      font-size: 2.2rem;
    }
  }

  p,
  ul,
  ol,
  figure,
  table,
  .kg-card,
  blockquote {
    + h2,
    + h3,
    + h4,
    + h5,
    + h6 {
      margin: 0.7em 0 0.5em;
    }
  }

  /* Paragraphs & Links ------------------------- */

  p {
    margin: 0 0 1.8em;
    line-height: 1.735;
    font-size: 1.8rem;

    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      font-size: 2rem;
    }
  }

  a {
    color: ${theme.color.secondary.base};
    border-bottom: 1px solid;
    transition: all 0.1s;

    &:hover {
      color: ${theme.color.secondary.base};
      background: ${theme.color.secondary.lightest};
    }

    strong,
    em {
      color: ${theme.color.primary.base};
    }
  }

  strong {
    color: ${props => props.theme.color.text.darker};
  }

  mark {
    background-color: #ffecb2;
    padding: 3px;
    border-radius: 4px;
  }

  hr {
    margin: 1.8em 0 3.2em;
    border: 0;
    border-top: 1px solid hsl(0deg 0% 90%);
  }

  img {
    height: auto;
  }

  /* Blockquote --------------------------------- */

  blockquote {
    font-size: 2.2rem;
    line-height: 1.5;
    margin: 1.8em 0 1.8em;
    padding-left: 0;
    position: relative;
    color: ${theme.color.text.lightest};

    &::before {
      color: ${theme.color.grey.darker};
      content: '\\201C';
      font-size: 4em;
      left: 0;
      line-height: 1;
      position: absolute;
      top: -0.6em;
      font-family: Georgia, 'Times New Roman', Times, serif;
    }

    p {
      margin-bottom: 0.5em;
      font-size: 2.2rem;
      line-height: 1.5;

      &:last-child {
        margin-bottom: 0;
      }
    }

    small,
    cite {
      display: block;
      font-size: 0.75em;
      font-style: normal;
      font-weight: normal;
      line-height: 1.5;
      margin-top: 1em;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media (min-width: ${theme.breakpoints.lg}) {
      font-size: 2.4rem;

      p {
        font-size: 2.4rem;
      }
    }
  }

  /* Lists -------------------------------------- */

  ul,
  ol {
    margin: 0 0 1.8em;
    padding: 0 0 0 1em;
    line-height: 1.6em;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  li {
    margin: 0 0 1em;

    &::marker {
      color: ${theme.color.secondary.base};
    }

    &:last-child {
      margin: 0;
    }
  }

  li > ul,
  li > ol {
    margin: 1em 0;
    padding: 0 0 0 2em;
  }

  li > ul {
    list-style: circle;
  }

  /* Table -------------------------------------- */

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 0px 0px 1.8em;
    text-align: left;
    font-size: 1.8rem;
  }

  table caption {
    color: ${theme.color.text.lightest};
    font-size: 1.6rem;
    margin-bottom: 0.9375em;
    text-align: left;
  }

  table th {
    border-bottom: 1px solid ${theme.color.grey.base};
    padding: 0.5em 5px;
    font-weight: 700;
    color: ${theme.color.text.darker};
  }

  table td {
    border-bottom: 1px solid ${theme.color.grey.base};
    padding: 0.5em 5px;
  }

  /* Embed Cards -------------------------------- */

  .kg-card {
    margin: 0 0 1.8em;

    figcaption {
      text-align: center;
      font-size: 1.6rem;
      padding: 8px 0 0;
      color: ${theme.color.text.lightest};
    }

    > .kg-card {
      margin: 0;
    }
  }

  .kg-image-card {
    img {
      border-radius: 6px;
      margin: auto;
      display: block;
      border: 1px solid ${theme.color.grey.base};
    }
  }

  .kg-embed-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .kg-width-wide {
    grid-column: wide-start / wide-end;
  }

  .kg-width-full {
    grid-column: full-start / full-end;
  }

  .kg-width-full img {
    width: 100%;
  }

  /* Gallery ------------------------------------ */

  .kg-gallery-card + .kg-gallery-card {
    margin-top: 0.75em;
  }

  .kg-gallery-container {
    position: relative;
  }

  .kg-gallery-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .kg-gallery-image img {
    display: block;
    margin: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    border: 1px solid ${theme.color.grey.base};
  }

  .kg-gallery-row:not(:first-of-type) {
    margin: 0.75em 0 0 0;
  }

  .kg-gallery-image:not(:first-of-type) {
    margin: 0 0 0 0.75em;
  }

  /* Code --------------------------------------- */

  ${prismCodeStyles}

  /* Margin bottom to accommodate shadow */
  p > code[class*='language-'],
  pre[class*='language-'] {
    background-color: #fdfdfd;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 1em;
  }

  /* Inline code */
  p > code[class*='language-'] {
    position: relative;
    padding: 0.2em;
    border-radius: 0.3em;
    color: #c92c2c;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: inline;
    white-space: normal;
  }

  .kg-code-card {
    pre {
      margin: 0;
    }
  }
`

export default PostContent
