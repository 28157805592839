import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

const ImgSharpInline = ({
  parentClassName,
  className,
  fluidImg,
  alt,
  maxWidth,
}) => {
  const classList = (parentClassName && parentClassName.split(` `)) || []
  const bookmark = classList.includes(`kg-bookmark-thumbnail`)
  const fluid = fluidImg && JSON.parse(fluidImg)

  return (
    <Img
      className={className}
      fluid={fluid}
      alt={alt}
      imgStyle={{objectFit: `${bookmark ? null : `contain`}`}}
    />
  )
}

ImgSharpInline.propTypes = {
  parentClassName: PropTypes.string,
  className: PropTypes.string,
  fluidImg: PropTypes.string.isRequired,
  alt: PropTypes.string,
  maxWidth: PropTypes.string,
}

export default ImgSharpInline
