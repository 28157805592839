import React from 'react'
import styled from 'styled-components'
import {GatsbyImage, getImage} from 'gatsby-plugin-image'
import {Link} from 'gatsby'

import BrowserWindow from './BrowserWindow'
import {Twitter} from '@styled-icons/bootstrap/Twitter'
import {Facebook} from '@styled-icons/bootstrap/Facebook'
import {Instagram} from '@styled-icons/bootstrap/Instagram'

const ShowcaseCard = ({post}) => {
  return (
    <Wrapper href={post.link} target="_blank" rel="noopener noreferrer">
      {post.screenshot && (
        <BrowserWindow
          image={post.screenshot}
          alt={`${post.title} website screenshot`}
          colorTheme="light"
        />
      )}
      <Content className={!post.logo ? 'no-logo' : ''}>
        <Left>
          <Title>{post.title}</Title>
          {post.description && (
            <Subtitle>{post.description.description}</Subtitle>
          )}
          {/* <Icons>
            {post.twitter && (
              <Icon href={post.twitter} target="_blank" rel="noopener">
                <Twitter />
              </Icon>
            )}
            {post.facebook && (
              <Icon href={post.facebook} target="_blank" rel="noopener">
                <Facebook />
              </Icon>
            )}
            {post.instagram && (
              <Icon href={post.instagram} target="_blank" rel="noopener">
                <Instagram />
              </Icon>
            )}
          </Icons> */}
          <Theme>
            Using{' '}
            <Link className="link--inline--secondary" to={post.themePath}>
              {post.themeName}
            </Link>
          </Theme>
        </Left>
        {/* <Right>
          {post.logo && <Logo image={getImage(post.logo)} alt={post.title} />}
        </Right> */}
      </Content>
      {/* <SiteLink href={post.link} target="_blank" rel="noopener noreferrer">
        link
      </SiteLink> */}
    </Wrapper>
  )
}

export default ShowcaseCard

const Title = styled.h2`
  font-size: 2.4rem;
  font-weight: ${p => p.theme.weights.bold};
  color: ${p => p.theme.color.text.darker};
`

const Wrapper = styled.a`
  position: relative;

  &:hover {
    ${Title} {
      text-decoration: underline;
    }
  }
`

const Content = styled.div`
  padding: 24px 0 0;
  display: grid;
  grid-template-columns: auto 80px;
  gap: 18px;
  grid-gap: 18px;

  &.no-logo {
    display: block;
  }
`

const Left = styled.div``
const Right = styled.div``

const Subtitle = styled.p`
  font-size: 1.7rem;
  margin: 8px 0 0;
  color: ${p => p.theme.color.text.lightest};
`

const Icons = styled.div``

const Icon = styled.a`
  width: 20px;
  display: inline-block;
  color: ${p => p.theme.color.text.darker};
  margin: 14px 8px 0 0;
  position: relative;
  z-index: 1;
`

const Theme = styled.p`
  margin: 14px 0 0;
  color: ${p => p.theme.color.text.lightest};
  font-size: 1.7rem;

  a {
    position: relative;
    z-index: 1;
  }
`

const Logo = styled(GatsbyImage)`
  box-shadow: ${p => p.theme.shadows.light};
  border-radius: 4px;
`

const SiteLink = styled.a`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`
