import React from 'react'
import styled from 'styled-components'

import {BoxedContainer, Section, SubtitleLarge} from '../elements'

const Hero = ({bg, children}) => (
  <Section padding="both" backgroundColor={bg}>
    <BoxedContainer>
      <HeroColumns>{children}</HeroColumns>
    </BoxedContainer>
  </Section>
)

const HeroColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  gap: 60px;
  grid-gap: 60px;

  @media (min-width: ${p => p.theme.breakpoints.md}) {
    grid-template-columns: 1fr 1.2fr;
  }

  @media (min-width: ${p => p.theme.breakpoints.lg}) {
    grid-template-columns: 1fr 1.6fr;
  }

  @media (min-width: ${p => p.theme.breakpoints.xl}) {
  }
`

const ContentColumn = styled.div`
  text-align: ${p => (p.centerAlign ? 'center' : 'left')};

  @media (min-width: ${p => p.theme.breakpoints.md}) {
    text-align: left;
  }
`

const ImageColumn = styled.div`
  justify-self: flex-end;

  @media (min-width: ${p => p.theme.breakpoints.lg}) {
    padding-right: 20px;
  }
`

const Title = styled.h1`
  color: ${p => p.theme.color.primary.base};
`

const Subtitle = styled(SubtitleLarge)`
  margin: 15px 0 0;

  @media (min-width: ${p => p.theme.breakpoints.lg}) {
    margin: 22px 0 0;
  }
`

const CTAs = styled.div`
  margin: 25px 0 0;

  @media (min-width: ${p => p.theme.breakpoints.lg}) {
    margin: 30px 0 0;
  }
`

export {Hero, Title, Subtitle, CTAs, ContentColumn, ImageColumn}
