import React from 'react'
import styled from 'styled-components'

import {BoxedContainer, Section} from '../elements'

const CTASingleButton = ({title, subtitle, link, bg, color}) => (
  <StyledSection padding="fixed" bg={bg} color={color}>
    <BoxedContainer>
      <Grid>
        <div>
          <h2>{title}</h2>
          {subtitle && <p>{subtitle}</p>}
        </div>
        <Links>
          {link && (
            <a
              href={link}
              className={`button--round button--round--with-arrow button--round--inverted`}
            >
              Submit
            </a>
          )}
        </Links>
      </Grid>
    </BoxedContainer>
  </StyledSection>
)

export default CTASingleButton

const StyledSection = styled(Section)`
  background: ${props =>
    props.bg ? props.bg : props.theme.color.secondary.base};
  color: ${props => (props.color ? props.color : props.theme.color.inverted)};
`

const Grid = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (min-width: ${p => p.theme.breakpoints.lg}) {
    flex-direction: row;
  }
`

const Links = styled.div`
  margin: 40px 0 0;

  @media (min-width: ${p => p.theme.breakpoints.lg}) {
    margin: 0 0 0 40px;
  }
`
