import React from 'react'
import styled from 'styled-components'

import NewsletterSubscribeForm from './NewsletterSubscribeForm'
import {BoxedContainer, Section} from '../elements'

const SubscribeCTA = () => (
  <Wrapper padding="both">
    <StyledBoxedContainer>
      <Text>
        <Title>Subscribe</Title>
        <Subtitle>
          Receive monthly newsletter with latest blog posts and upates
        </Subtitle>
      </Text>
      <Form>
        <NewsletterSubscribeForm />
      </Form>
    </StyledBoxedContainer>
  </Wrapper>
)

export default SubscribeCTA

const Wrapper = styled(Section)`
  background: ${p => p.theme.color.secondary.base};
`

const StyledBoxedContainer = styled(BoxedContainer)`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const Text = styled.div`
  color: #fff;
`

const Title = styled.h2``

const Subtitle = styled.p``

const Form = styled.div``
