import React from 'react'
import PropTypes from 'prop-types'
import rehypeReact from 'rehype-react'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import ImgSharpInline from './ImgSharpInline'
import PostContent from './PostContent'
import {GridCanvas} from '../elements'

const ImageZoom = ({className, alt, src, loading}) => {
  return (
    <Zoom>
      <img className={className} alt={alt} src={src} loading={loading} />
    </Zoom>
  )
}

const renderAst = new rehypeReact({
  Fragment: React.Fragment,
  createElement: React.createElement,
  components: {
    'img-sharp-inline': ImgSharpInline,
    img: ImageZoom,
  },
}).Compiler

const RenderContent = ({htmlAst, html}) => (
  <React.Fragment>
    {htmlAst ? (
      <PostContent className="post-content load-external-scripts">
        <GridCanvas>{renderAst(htmlAst)}</GridCanvas>
      </PostContent>
    ) : (
      <PostContent className="post-content load-external-scripts post-content--original">
        <GridCanvas dangerouslySetInnerHTML={{__html: html}} />
      </PostContent>
    )}
  </React.Fragment>
)

RenderContent.propTypes = {
  htmlAst: PropTypes.object,
  html: PropTypes.string.isRequired,
}

export default RenderContent
